.wrap-search-home-page {
	position: relative;
	display: block;
	min-width: 100vw;
	min-height: 100vh;

	.wrap-layer-page {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		min-height: 100%;
		top: 0;
		right: 0;
		z-index: 1000;
		user-select: none;
		background-color: #ffffff00;
		cursor: pointer;
	}
	.topBar {
		&.downSlide,
		&.upSlide {
			position: relative;
			transform: initial;
			top: 0;
			z-index: 0;
		}
	}
	.sideBarPanel.sideBarSearchFilter {
		position: initial;
		margin: 0;
		float: right;
		box-shadow: none;
		min-height: 100vh;
		.searchFilterWrapper {
			min-height: 100vh;
			height: 100%;
			.section.range,
			.colorLabel {
				font-size: 15px;
			}
			.section.range ~ .range {
				padding-bottom: 100px;
			}
			.wrapInnerFilterSearch {
				padding-bottom: 0;
				min-height: 100%;
				.buttonsSection2 .btn {
					font-size: 1.5rem;
				}
			}
		}
		.section.fixed.bottom {
			.mainAccountBtn {
				padding: 0.3rem 2.2rem 0.3rem 4.2rem;
				width: 270px;
				height: 60px;
				font-weight: 600;
				font-size: 2rem;
				&::after {
					width: 25px;
					height: 25px;
					left: 20px;
				}
			}
		}
	}
	.searchPage {
		padding-top: 50px;
		.wrapAllResults {
			margin-top: 0;
			font-size: 1.5rem;
			.card .cardImgBtnTxt .wrapShortTxt h4 {
				font-size: 1.7rem;
			}
		}
	}
}
