.wrapBlogCategoryPage {
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 100px);
	position: relative;
	overflow: hidden;

	.skeleton {
		transform: scale(1);
		width: 100%;
		height: 100%;
	}

	.blogCategoryPage {
		min-height: calc(60vh - 100px);
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: #f8f8f8;
		position: relative;

		.blogCategoryWrapper {
			display: flex;
			flex-direction: column;
			position: relative;
			width: 85%;
			max-width: 1200px;
			min-height: max-content;
			color: #000;
			text-align: right;
			font-style: normal;
			font-weight: 600;

			.categoriesWrapper {
				display: flex;
				align-items: center;
				padding: 50px 0;
				height: min-content;
				gap: 25px;
				flex-wrap: wrap;
				font-size: 18px;
				font-weight: 400;
				line-height: 1;
				span,
				img {
					cursor: pointer;
				}
				.wrapCategoriesNames {
					display: flex;
					max-width: 100%;
					overflow: auto;

					&::-webkit-scrollbar {
						opacity: 0;
						padding: 0;
						width: 0;
						height: 0;
					}
					span {
						margin-left: 25px;
					}
				}
			}

			.wrapContentBlogs {
				display: flex;
				flex-direction: column;
				width: 100%;
				height: 100%;
				gap: 30px;

				.blogHeader {
					cursor: pointer;
					display: flex;
					flex-direction: column;
					padding: 0;
					color: #000;
					text-align: right;
					font-size: 20px;
					font-weight: 400;
					line-height: 1;
					.wrapBlogHeaderImg {
						position: relative;
						.categorySlugHeader {
							background: #f65368;
							color: #fff;
							padding: 2px 15px;
							cursor: pointer;
							position: absolute;
							border-radius: 5px 0 0 5px;
							bottom: 15px;
							right: 0;
							font-size: 24px;
						}
					}

					img {
						border-radius: 20px 20px 0px 0px;
						width: 100%;
						max-width: 100%;
						height: 350px;
						object-fit: cover;
					}
					.innerHeader {
						display: flex;
						flex-direction: column;
						gap: 30px;
						background-color: #fff;
						padding: 40px 30px;
						h1 {
							font-size: 70px;
							font-weight: 600;
							line-height: 1;
							letter-spacing: -0.7px;
							margin: 0;
							color: #000;
						}
						h2 {
							font-weight: 700;
							font-size: 20px;
							color: #000;
							margin: 0;
						}
						.innerContentHeader {
							height: 100px;
							max-height: 100px;
							width: 100%;
							font-size: 20px;
							overflow: hidden;
							p {
								color: #000;
							}
						}
						.authorAndDate {
							display: flex;
							gap: 16px;
							align-items: center;

							.name {
								border-radius: 5px;
								background: #e1e9f2;
								padding: 8px;
							}
							.withImg {
								display: flex;
								cursor: pointer;
								.wrapPublicUserImg {
									display: flex;
									justify-content: center;
									align-items: center;
									position: relative;
									margin: 0 0 0 10px;
									width: 40px;
									img {
										position: absolute;
										width: 45px;
										height: 45px;
										border-radius: 50%;
										object-fit: cover;
									}
								}
							}
							.wrapViewNDate {
								display: flex;
								gap: 16px;
							}
						}
					}
				}
				.wrapContent {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: start;
					align-items: start;
					padding: 30px 0;
					.landingBlog {
						width: 100%;
						padding: 0;
						.wrapBlogs {
							justify-content: start;
							flex-wrap: wrap;
							.cardBlog {
								width: 350px;
								box-shadow: none;
								cursor: pointer;
							}
						}
					}
				}
			}
			.wrapSuppliers {
				padding: 30px 0;
				background-color: #f8f8f8;
				.suppliersTitle {
					margin: 0;
					font-size: 20px;
				}
				.landingSatisfiedSuppliers {
					background-color: #f8f8f8;
					margin: 0;
					padding: 0;
					.swiper .swiper-slide .TalentBox {
						cursor: pointer;
						z-index: initial;
					}
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		.blogCategoryPage {
			min-height: calc(100vh - 84px);
			background: #fff;
			.blogCategoryWrapper {
				top: 0;
				width: 100%;
				max-width: 100%;
				padding: 0 8px 30px;
				background-color: #f8f8f8;
				.categoriesWrapper {
					padding: 15px 0 30px;
					gap: 15px;
					flex-wrap: nowrap;
					font-size: 16px;

					.wrapCategoriesNames {
						span {
							white-space: nowrap;
							margin-left: 15px;
						}
					}
				}

				.wrapHeaderContent {
					padding: 24px;
				}

				.wrapContentBlogs {
					flex-direction: column;
					.blogHeader {
						.wrapBlogHeaderImg {
							.categorySlugHeader {
								padding: 0px 7px;
								bottom: 15px;
								right: 0;
								font-size: 20px;
							}
						}
						img {
							height: 200px;
						}
						.innerHeader {
							gap: 20px;
							padding: 20px;
							border-radius: 0px 0px 20px 20px;

							h1 {
								font-size: 32px;
							}
							h2 {
								font-weight: 400;
								font-size: 18px;
							}
							.innerContentHeader {
								display: none;
							}
							.authorAndDate {
								gap: 10px;

								.name {
									// white-space: nowrap;
								}
								.withImg {
									.wrapPublicUserImg {
										width: 45px;
									}
								}
								.wrapViewNDate {
									white-space: nowrap;
									gap: 8px;
									span:last-child {
										display: none;
									}
								}
							}
						}
					}

					.wrapContent {
						padding: 0;
						.landingBlog {
							width: 100%;
							padding: 0;
							.wrapBlogs {
								align-items: center;
								padding: 0;
								.cardBlog {
									border-radius: 20px;
									border-left: 0;
									width: 350px;
									box-shadow: none;
									cursor: pointer;
								}
							}
						}
					}
				}

				.wrapSuppliers {
					.suppliersTitle {
						text-align: center;
					}
					.swiper {
						padding-right: 24px;
					}
				}
			}
		}
	}
}
