@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0) rotate(-3deg);
		opacity: 1;
	}
}

@keyframes slideInFromTop2 {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media (max-width: 1023px) {
	.mobile-tablet-only {
		display: block;
	}
}

@media (min-width: 1024px) {
	.mobile-tablet-only {
		display: none;
	}
}

@media (min-width: 1024px) {
	.desktop-only {
		display: block;
	}
}

@media (max-width: 1023px) {
	.desktop-only {
		display: none;
	}
}

.flash {
	animation: flash 1s infinite;
}

.flash2 {
	animation: flash 1s 5 forwards;
	animation-delay: 7s;
}

.slide-in-left {
	--delay: 2s; /* ערך ברירת מחדל לעיכוב */
	opacity: 0;
	animation: slideInFromLeft 1.5s ease-out var(--delay) forwards;
}

.image-container-desktop {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: 20% center;

	background-image: url("../../images/newLogin/newHpBg.jpg");
	min-height: 100vh;

	&.homeAsBG {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		&:after {
			content: "";
			transition: background-color 300ms linear;
			background-color: #000000b9;
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 2000;
			pointer-events: none;
		}
		@media screen and (max-width: 780px) {
			display: none;
		}
	}
	.collaborationWrapper {
		font-size: 2.5rem;
		font-weight: 600;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		opacity: 0;
		animation: fadeIn 2s forwards;
		position: absolute;
		top: 90px;

		.newRegister {
			background: #faf1b3;
			color: #f65368;
			padding: 5px 10px;
			border-radius: 10px;
			transform: rotate(-1deg);
			z-index: 1;
			text-align: center;
			width: fit-content;
		}
		.shareWith {
			width: fit-content;
			background: #f65368;
			color: #fff;
			padding: 5px 10px;
			border-radius: 10px;
			z-index: 2;
			text-align: center;
		}
	}
	.logo {
		position: absolute;
		top: 40px;
		right: 160px;
	}
	.login {
		padding: 40px;
		flex-direction: column;
		align-items: center;
		gap: 50px;
		border-radius: 20px;
		background: #fff;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
		position: absolute;
		top: 280px;
		right: 160px;
		height: 450px;
		width: 450px;
		.terms {
			color: #2d5086;
			text-align: right;

			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 50px;

			b {
				text-decoration-line: underline;
				text-decoration-style: solid;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: auto;
				text-underline-offset: auto;
				text-underline-position: from-font;
			}
		}
		.title {
			color: #2d5086;
			text-align: center;
			//
			font-size: 28px;
			font-style: normal;

			line-height: 131.8%; /* 44.812px */
			margin-bottom: 30px;
		}
		button {
			margin-top: 30px;
			display: flex;
			padding: 10px 16px;
			justify-content: center;
			align-items: center;
			gap: 33px;
			align-self: stretch;
			border-radius: 20px;
			background: #f65368 !important;
			color: var(--, #fff);

			font-size: 33px;
			font-style: normal;

			line-height: normal;
			width: 100%;
		}

		input {
			height: 55px !important;
		}
		select {
			height: 55px !important;
		}
		.PhoneInputInput {
			height: 55px !important;
			border-radius: 0 7px 7px 0;
		}
		.phoneLP {
			width: 100%;
		}
	}
	.boxes {
		position: absolute;
		top: 330px;
		left: 580px;
		.blue-box {
			background-image: url("../../images/newLogin/RectangleBlue.png");
			width: 403px;
			height: 207px;
			padding: 20px;
			ul.custom-list {
				list-style: none;
				padding: 0;
				margin: 0;
				li {
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					color: #fff;
					text-align: right;

					font-size: 17px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}

			ul.custom-list li::before {
				content: "";
				background-image: url("../../images/newLogin/check.svg");
				background-size: contain;
				background-repeat: no-repeat;
				width: 20px;
				height: 20px;
				margin-left: 10px;
			}
		}
		.white-box {
			background-image: url("../../images/newLogin/white.png");
			width: 275px;
			height: 243px;
			flex-shrink: 0;
			padding: 80px;
			position: absolute;
			top: 153px;
			left: 180px;
			z-index: 20;
			.flash {
				animation: flash 1s 5 forwards;
				animation-delay: 3s;
			}
			span {
				display: block;
				text-align: center;
				font-size: 30px;
				font-style: normal;
				font-weight: 400;
				line-height: 132.8%;
				color: #2d5086;
			}
		}
		.new-site-message {
			position: absolute;
			top: -99px;
			z-index: 20;
			left: 41px;
			.top {
				opacity: 0;
				animation: slideInFromTop 1s ease-out 3s forwards;
				transform: rotate(-3deg);
				color: #f65368;
				text-align: right;
				font-size: 36.429px;
				font-style: normal;
				font-weight: 700;
				line-height: 131.8%; /* 48.013px */
			}
			.bottom {
				opacity: 0; /* מתחיל בלתי נראה */
				animation: fadeIn 2s ease-in 3.5s forwards; /* משך האנימציה 2 שניות */
				display: flex;
				width: 187.121px;
				height: 69.542px;
				transform: rotate(-3deg);
				padding: 14.612px 33.084px 19.499px 33.084px;
				justify-content: center;
				align-items: center;
				gap: 14.979px;
				flex-shrink: 0;
				color: #fff;
				text-align: right;
				leading-trim: both;
				text-edge: cap;
				font-size: 49.626px;
				font-style: normal;
				font-weight: 400;
				line-height: 131.8%; /* 65.408px */
				border-radius: 89.872px;
				background: #f65368;
			}
		}
	}
	.top-menu {
		position: absolute;
		top: 24px;
		left: 150px;
		display: inline-flex;
		padding: 17px 30px;
		align-items: flex-start;
		gap: 50px;
		border-radius: 30px;
		background: #fff;
		width: 656px;
		.top-menu-item {
			color: #2d5086;

			font-size: 25px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			&:hover {
				font-weight: bold;
			}
		}
	}
	.old-site {
		position: absolute;
		right: 0px;
		bottom: 0px;
		.bg-old-site {
			position: absolute;
			right: 0px;
			bottom: 0px;
		}
		.content {
			position: absolute;
			right: 0px;
			bottom: 0px;
			z-index: 10;
			padding: 20px;

			.line1 {
				color: #fff;
				text-align: right;
				font-size: 28px;
				font-style: normal;
				font-weight: 700;
				line-height: 34.376px; /* 122.772% */
			}
			.line2 {
				color: #fff;
				text-align: right;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 25px; /* 138.889% */
				margin-bottom: 12px;
			}
			.line3 {
				a {
					display: flex;
					width: 300.095px;
					height: 36.605px;
					padding: 14px;
					justify-content: center;
					align-items: center;
					gap: 6.7px;
					flex-shrink: 0;
					border-radius: 33.628px;
					border: 1.601px solid #fff;
					background: #547acd;
					color: #fff;
					font-family: Rubik;
					font-size: 19px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}
		}
	}
}

.mobile-container {
	position: relative;
	&.homeAsBG {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		&:after {
			content: "";
			transition: background-color 300ms linear;
			background-color: #000000b9;
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 2000;
			pointer-events: none;
		}
		@media screen and (max-width: 780px) {
			display: none;
		}
	}

	.collaborationWrapper {
		font-size: 2.5rem;
		font-weight: 600;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		opacity: 0;
		animation: fadeIn 2s forwards;

		.newRegister {
			background: #faf1b3;
			color: #f65368;
			padding: 5px 10px;
			border-radius: 10px;
			transform: rotate(-1deg);
			z-index: 1;
			text-align: center;
			width: fit-content;
		}
		.shareWith {
			width: fit-content;
			background: #f65368;
			color: #fff;
			padding: 5px 10px;
			border-radius: 10px;
			z-index: 2;
			text-align: center;
		}
	}
	.gradient-overlay {
		position: absolute;
		padding-top: 75px;
		top: calc(100% - 372px);
		left: 0;
		width: 100%;
		min-height: calc(100% + 206px);
		//background: linear-gradient(to bottom, #2d5086 30%, #2e4f85 70%, #324f86 100%);
		z-index: 1;
		background: linear-gradient(180deg, rgba(45, 80, 134, 0) 0%, #2d5086 34%);
		.new-app {
			opacity: 0;
			animation: slideInFromTop2 1s ease-out 1.2s forwards;
			color: #fff;
			text-align: center;
			font-family: Rubik;
			font-size: 22px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 108px;
		}
		.download-app {
			padding: 9px 19px 10px 17px;

			gap: 9.718px;
			border-radius: 0 30px 30px 0;
			background: #fff;
			position: absolute;
			left: -2px;
			bottom: 65px;
			color: #2d5086;

			.line1 {
				text-align: right;
				font-family: Rubik;
				font-size: 8.924px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			.line2 {
				display: flex;
				text-align: center;
				font-family: Assistant;
				font-size: 19.678px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				img {
					width: 18.042px;
					height: 22.131px;
					flex-shrink: 0;
					margin-top: -3px;
					margin-right: 6px;
				}
			}
		}
		.logo {
			width: 290px;
			margin: 0 auto;
			margin-top: 43px;
		}

		.button-register {
			display: flex;
			border: none;
			width: 290px;
			height: 64px;
			padding: 19.388px 22.6px 22.6px 22.6px;
			justify-content: center;
			align-items: center;
			gap: 14.703px;
			flex-shrink: 0;
			border-radius: 33.628px;
			background: #f65368;
			margin: 0 auto;
			margin-top: 50px;
			color: #fff;
			leading-trim: both;
			text-edge: cap;
			font-family: Rubik;
			font-size: 32px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.terms {
			color: #fff;
			text-align: center;
			font-family: Rubik;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 25px;

			b {
				text-decoration-line: underline;
				text-decoration-style: solid;
				text-decoration-skip-ink: auto;
				text-decoration-thickness: auto;
				text-underline-offset: auto;
				text-underline-position: from-font;
			}
		}

		.sticky-bottom {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #5379cc;
			height: 50px;
			color: #fff;
			text-align: center;
			font-family: Rubik;
			font-size: 16.389px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding-top: 13px;
			img {
				margin-left: 10px;
			}
			a {
				color: #fff;
			}
		}
	}

	.mobile-image {
		width: 100%;
		object-fit: cover;
		object-position: 0 -70px;
	}
	.logo {
		display: block;
		width: 100%;
	}
}
