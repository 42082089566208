.notificationWrapper {
	border-radius: 20px;
	max-height: 80px;

	@media screen and (max-width: 780px) {
		top: 15px;
		align-items: start !important;
		@media screen and (min-width: 600px) {
			justify-content: center !important;
			width: 100%;
		}
	}

	.MuiSnackbarContent-root {
		background-color: #fff;
		border-radius: 20px;

		.MuiSnackbarContent-message {
			color: #2d5086;
			width: 100%;

			.wrapNotificationMsg {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 3px;
				width: 100%;

				img {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					object-fit: fill;
					margin: 0 5px;
				}
			}
		}
	}
}
.IOSView {
	@media screen and (max-width: 600px) {
		top: 30px;
		.MuiSnackbarContent-root {
			align-self: center;
		}
	}
}
