$bluePageText: #fff;
$bluePageBackground: #2d5086;
$bluePageLinear: linear-gradient(180deg, #2d508600 10%, #2d5086d6 53%, #2d5086ed 59%, #2d5086 100%);

$whitePageText: #2d5086;
$whitePageBackground: #fff;
$whitePageLinear: linear-gradient(
	180deg,
	#ffffff00 10%,
	#ffffffd6 53%,
	#ffffffed 59%,
	#ffffff 100%
);

.commonTemplateSpeedDateWrapper {
	width: 100vw;
	height: 100vh;
	position: relative;
	.text {
		text-align: center;
		font-size: 19px;
	}

	.imgHeader {
		width: 100vw;
		position: relative;
		display: block;
		img {
			width: 100%;
			object-fit: contain;
			position: fixed;
			top: calc(0 + env(safe-area-inset-top));
		}
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			right: 0;
			height: 15%;
			bottom: -1px;
			z-index: 1;
			pointer-events: none;
		}

		.logoBlue {
			position: relative;
			top: calc(1rem + env(safe-area-inset-top));
			right: 1rem;
		}
	}

	&.wideImg {
		.imgHeader {
			height: 8vh;
		}
	}
	&.narrowImg {
		.imgHeader {
			height: 20vh;
		}
	}

	&.bluePage {
		.text {
			color: $bluePageText;
		}
		.imgHeader:after {
			background: $bluePageLinear;
		}
		.speedDateContentWrapper {
			background: $bluePageBackground;
		}
	}
	&.whitePage {
		.text {
			color: $whitePageText;
		}
		.imgHeader:after {
			background: $whitePageLinear;
		}
		.speedDateContentWrapper {
			background: $whitePageBackground;
		}
	}
}
.speedDateFooterBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	button {
		background-color: #f65368;
		color: #fff;
		border-radius: 30px;
		font-size: 25px;
		padding: 8px 20px;
		&:hover {
			background-color: #fff;
			color: #f65368;
			border: #fff 1px solid;
		}
		span {
			color: #fff !important;
			font-size: 20px !important;
			margin: 0 !important;
			&:hover {
				color: #f65368 !important;
			}
		}
	}
}
