.speedDatePageWrapper {
	display: grid;
	grid-template-rows: 80px minmax(0, 1fr) 120px;
	height: 100vh;
	overflow: hidden;
	padding-top: calc(20px + env(safe-area-inset-top));

	.headerVideoStatus {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: start;
		.videoStatusItem {
			width: 100%;
			background: #67c41b;
			color: #fff;
			margin: 0 20px 10px;
			border-radius: 20px;
			padding: 10px 20px;
			text-align: center;
			&.startsIn {
				background: #0bc1c7;
				.time {
					background: #2d5086;
					border-radius: 5px;
					padding: 4px;
				}
			}
			&.break {
				display: flex;
				justify-content: space-between;
				.breakImg {
					display: flex;
					gap: 3px;
					align-items: center;
					font-weight: bold;

					img {
						object-fit: contain;
					}
				}
			}
			&.dateWith {
				display: flex;
				align-items: center;
				gap: 10px;
				justify-content: center;
				.lastMinuet {
					color: red;
					opacity: 1;
					animation: fadeAnimation 1s infinite alternate;
				}

				@keyframes fadeAnimation {
					0% {
						opacity: 0.9;
					}
					50% {
						opacity: 0.1;
					}
					100% {
						opacity: 0.9;
					}
				}
			}
		}
	}

	// .joinDate {
	// 	width: 100%;
	// 	text-align: center;
	// 	position: absolute;
	// 	// bottom: 80px;
	// 	color: #2d5086;
	// 	background-color: #fff;
	// 	padding: 10px;
	// cursor: pointer;
	// }

	.jeesMeetingWrapper {
		margin: 0 20px 20px;
		border-radius: 20px;
		overflow: hidden;
		position: relative;

		.enterDateWrapper {
			width: 90%;
			max-width: 400px;
			height: 100%;
			max-height: 600px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 20px;
			border-radius: 20px;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #eaeff8;

			.enterDate {
				background: #f65368;
				color: #fff;
				padding: 5px 20px;
				border-radius: 20px;
				cursor: pointer;
			}
		}

		.jeesIframe {
			position: relative;
			iframe {
				position: relative;
			}
		}
	}

	.placeholderVideo {
		background: #eaeff8;
		margin: 20px;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		img {
			object-fit: contain;
			max-width: 100%;
			max-height: 100%;
		}
		.smallImg {
			position: absolute;
			top: 10px;
			right: 10px;
			background: #fff;
			border-radius: 20px;
			height: 30%;
			width: 30%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				object-fit: contain;
				width: 100%;
			}
		}
		.testVideoBtn {
			position: absolute;
			bottom: 10px;
			border: none;
			text-decoration: underline;
		}
	}
	.newPlaceholder {
		width: 100%;
		background: #fff;
		margin: 0;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		img {
			object-fit: contain;
			max-width: 100%;
			max-height: 100%;
		}
		.wrapWelcome {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-self: start;
			margin-top: 0;
			gap: 5px;
			color: #2d5086;
			h3 {
				font-size: 1.7rem;
				font-weight: bold;
				margin: 0;
			}
			h2 {
				font-size: 1.3rem;
				margin: 0;
			}
			span {
				font-size: 1rem;
			}
			img {
				position: relative;
				bottom: -20px;
				max-width: 450px;
			}
			.instructions-list {
				list-style-type: none;
				padding: 20px;
				margin: 0;
				z-index: 1;
				span,
				b,
				li {
					position: relative;
					margin-bottom: 0.2em;
					font-size: 18px;
				}
				li {
					padding-right: 1.5em;
					&::before {
						content: "☑";
						position: absolute;
						right: 0;
						font-size: 1.2em;
						font-weight: bold;
						color: #2d5086;
					}
				}
			}
		}
		.breakManImg {
			img {
				bottom: -30px;
				width: 100vw;
			}
		}
	}
	.vdHeart {
		align-self: center;
		justify-self: center;
	}

	.bottomDrawer {
		background: #defeff;
	}
}

.videoDrawerWrapper {
	.wrapDatesLines {
		padding: 3px 20px 50px;

		.lineDate {
			margin: 20px 0;
			width: 100%;
			padding: 0 16px;
			border-radius: 10px;
			color: #2d5086;
			font-size: 16px;
			display: flex;
			flex-direction: column;
			background: #bcfdff;
			overflow: hidden;
			position: relative;
			height: 50px;
			transition: height 0.5s ease;
			max-height: 275px;
			button {
				color: #2d5086;
			}

			&.open {
				height: 100%;
				overflow: auto;
			}
			&.time {
				font-weight: bold;
			}
			&.timePassed {
				// opacity: 0.5;
				background: #eefdff;
			}
			&.current {
				opacity: 1;
				background: #0bc1c7;
				color: #fff;

				.wrapQuesAns {
					.MuiRating-root {
						.MuiRating-iconFilled {
							border: #edfafb 1px solid;
						}
					}
					button {
						color: #fff;
					}
				}
			}
			.wrapContent {
				display: flex;
				gap: 5px;
				width: 100%;
				padding: 13px 0;
			}

			.wrapQuesAns {
				padding-bottom: 20px;
				.MuiRating-root {
					margin-top: 10px;
					.MuiRating-icon {
						padding: 0px 5px;
						border-radius: 5px;
						color: #2d5086;
					}

					.MuiRating-iconFilled {
						border: #2d5086 1px solid;
					}
				}
				button {
					padding: 5px 15px;
					height: min-content;
					border-radius: 10px;
					margin-top: 10px;
					&.disabled {
						border: none !important;
					}
					&.active {
						border: 1px solid !important;
					}
				}
			}
		}
		.breakLine {
			height: min-content;
			background: #45669a;
			color: #fff;
			text-align: center;
			padding: 0;
			&.timePassed {
				opacity: 0.5;
				background: #45669a;
			}
			&.current {
				opacity: 1;
				background: #0bc1c7;
			}
		}

		.importantToKnow {
			border-top: 1px solid;
			margin: 40px 0 0;
		}
	}
}

.modal-dialog.simpleDialog .modal-content .modal-body {
	.wrapQuesAns {
		padding-bottom: 20px;
		legend {
			color: #2d5086;
		}
		.MuiRating-root {
			margin-top: 10px;
			.MuiRating-icon {
				padding: 0px 5px;
				border-radius: 5px;
				color: #2d5086;
				max-width: 100% !important;
			}

			.MuiRating-iconFilled {
				border: #2d5086 1px solid;
			}
		}
		.MuiBox-root {
			display: flex;
			justify-content: center;
		}
		button {
			padding: 5px 15px;
			height: min-content;
			border-radius: 10px;
			margin-top: 10px;
			&.disabled {
				border: none !important;
			}
			&.active {
				border: 1px solid !important;
			}
		}
	}
	.reportVd {
		max-width: 100%;
		font-size: 14px;
	}
}
