.rating-card {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	overflow: visible;
	.rating-card__container {
		border-radius: 30px;
		overflow: visible;
		border-bottom: solid 10px #2d5086;
		.rating-card_content_container {
			padding: 60px 18px 30px;
			.rating-card__header {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 100%;
				top: -150px;
				left: 0;
				img {
					// position: absolute;
					// top: -50px;
					max-width: 120px;
					width: 100%;
				}
			}
			.rating-card__title {
				margin-bottom: 20px;
			}
			.rating-card__stars {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 20px 0 40px;
				padding-bottom: 10px;
				span {
					max-width: 100% !important;
					opacity: 1;
				}
				.rating-card__star-icon {
					span {
						font-size: 3.2rem;
						color: #f65368;
					}
				}
			}

			.rating-card__feedback {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				.rating-card__best {
					font-size: 1.5em;
				}
				.rating-card__emoji {
					position: relative;
					width: 30px;
					.rating-card__arrow {
						height: 55px;
						position: absolute;
						left: -25px;
						top: -55px;
					}
				}
			}
			.rating-card__submit-btn {
				margin-top: 30px;
				background: #f65368 !important;
				border-radius: 20px;
				padding: 5px 40px;
				font-size: 1.2rem;
				border: 1px solid #f65368 !important;
			}
			.MuiCircularProgress-root {
				margin-top: 30px;
				color: #f65368 !important;
			}
			.rating-card__comment {
				fieldset {
					border: 2px solid #bbb;
					border-radius: 20px;
					border-color: #bbb !important;
				}
			}
		}
	}
}

.modal-dialog.simpleDialog.ratingDialog {
	max-width: 500px;
	background-color: #00000000;
	.modal-content {
		background-color: #00000000;
		.user-chat-nav {
			margin: 0 0 20px 20px;
			.nav-btn {
				color: white;
			}
		}
	}
}
