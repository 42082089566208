.blogSurveysWrapper {
	padding: 70px 60px;

	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;
	justify-content: center;

	.surveyBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;
		border: #2d5086 1px solid;
		min-width: 330px;
		border-radius: 20px;
		width: 100%;
		overflow: hidden;

		h4 {
			padding: 30px 0px 20px;
			font-size: 28px;
			font-weight: 600;
			margin: 0;
			text-align: center;
			width: 100%;
			background: linear-gradient(89deg, #2d5086 0%, #2d5086 30%, #2b83ab 85%, #0bc1c7 100%);
			color: #fff;
		}

		.wrapSurveyBtns {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 25px;
			padding: 0px 30px 40px;

			.surveyBtn {
				min-width: 300px;
				border: 1px solid #2d5086;
				border-radius: 20px;
				color: #2d5086;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 20px;
				font-weight: 400;

				.checkBoxAndText {
					display: flex;
					align-items: center;
					justify-content: start;
					gap: 10px;
					.MuiCheckbox-root {
						background: initial;
					}
				}

				&:hover {
					background-color: #ddf9f9;
				}
			}
		}
	}
	.answerSurveyBox {
		overflow: hidden;
		h4 {
			background: linear-gradient(89deg, #2d5086 0%, #2d5086 30%, #2b83ab 85%, #0bc1c7 100%);
			color: #fff;
		}
		.wrapSurveyBtns {
			.surveyBtn {
				position: relative;
				opacity: 0;
				transition: opacity 1s ease-in;
				&:hover {
					background-color: initial;
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		padding: 30px 40px;
	}
}
