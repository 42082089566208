$fprimary: invert(27%) sepia(12%) saturate(3595%) hue-rotate(180deg) brightness(94%) contrast(85%);

.audio-wrapper {
	.siteColor {
		color: #2d5086;
	}
	.audio-header {
		display: flex;
		flex-direction: row-reverse;
		gap: 5px;
		align-items: center;
		button {
			min-width: 40px;
			width: 40px;
			height: 40px;
		}
		.playBox {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			position: relative;
			.playText {
				position: absolute;
				bottom: -20px;
			}
		}
	}
	.audio-bar {
		display: flex;
		justify-content: space-around;
		.audio-bar-box {
			cursor: pointer;
			display: flex;
			height: min-content;
			justify-content: center;
			align-items: center;
		}
	}

	.play_icon {
		position: relative;
		width: 40px;
		height: 25px;
		margin: 0 !important;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: 20px;
			background-image: url("../images/icons/play1.svg");
			background-position: center;
			background-repeat: no-repeat;
			top: 0px;
			filter: $fprimary;
			right: 0;
			left: 0;
		}
	}

	.pause_icon {
		position: relative;
		width: 40px;
		height: 40px;
		margin: 0 !important;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: 20px;
			background-image: url("../images/icons/pause1.svg");
			background-position: center;
			background-repeat: no-repeat;
			top: 0px;
			filter: $fprimary;
			right: 0;
			left: 0;
		}
	}

	.stop_icon {
		position: relative;
		width: 40px;
		height: 40px;
		margin: 0 !important;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: 35px;
			background-image: url("../images/icons/stop1.svg");
			background-position: center;
			background-repeat: no-repeat;
			top: 0px;
			filter: $fprimary;
			right: 0;
			left: 0;
		}
	}

	.trash_icon {
		position: relative;
		width: 20px;
		height: 40px;
		margin: 0 !important;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: 16px;
			background-image: url("../images/icons/trash1.svg");
			background-position: center;
			background-repeat: no-repeat;
			top: 0px;
			filter: $fprimary;
			right: 0;
			left: 0;
		}
	}

	.mic_icon {
		position: relative;
		width: 20px;
		height: 40px;
		margin: 0 !important;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: 16px;
			background-image: url("../images/icons/mic1.svg");
			background-position: center;
			background-repeat: no-repeat;
			top: 0px;
			// filter: invert(42%) sepia(99%) saturate(7254%) hue-rotate(355deg) brightness(91%)
			// 	contrast(129%);
			filter: $fprimary;
			right: 0;
			left: 0;
		}
	}
}
