//Import Icon css
@import "./icons.scss";

//light mode
@import "./bootstrap.scss";
@import "./app.scss";

// rtl mode
//@import "./app-rtl.scss";

.nav-item {
	cursor: pointer;
}

.dropdown {
	cursor: pointer;
}

.slick-slide {
	margin-left: 8px;
	margin-right: 8px;
}

.slick-arrow {
	display: none;
}

.emoji-mart {
	border: none !important;
}

.emoji-mart-preview {
	display: none !important;
}

.emoji-mart-bar {
	border: none !important;
}

.alice-carousel__stage {
	direction: rtl;
}
.alice-carousel__stage-item {
	margin-right: 8px !important;
	width: 71px !important;
}

.input-file {
	label {
		margin-bottom: 0;
	}
	input[type="file"] {
		display: none;
	}
}

.emoji-mart-light {
	background-color: $card-bg !important;
}

.emoji-mart-search {
	input {
		background-color: $card-bg !important;
		border-color: $card-border-color !important;
	}
}

.emoji-mart-category-label {
	span {
		background-color: $card-bg !important;
		color: $gray-700;
	}
}

.emoji-mart-category {
	.emoji-mart-emoji {
		&:hover {
			background-color: $gray-300 !important;
		}

		&::before {
			background-color: $gray-300 !important;
			border-radius: 100% !important;
		}
	}
}
.w-70 {
	width: 75% !important;
}

.ctext-wrap-content {
	animation: flyIn 0.6s ease-in-out;
}

.blank-div {
	width: 36px;
	height: 36px;
}

.profile-user-dropdown {
	.dropdown-item {
		&.active {
			color: $white;
			text-decoration: none;
			background-color: $primary;
		}
	}
}
.dot_remove {
	.alice-carousel__dots,
	.alice-carousel__prev-btn,
	.alice-carousel__next-btn {
		display: none !important;
	}
}
.close {
	background: transparent;
	border: 0;
	font-size: 30px;
	margin: 10px;
	// background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0.5;
	width: 1em;
	height: 1em;
	z-index: 2;
}

/*Ayelet*/
.closeGalleryModal {
	color: #000;
	font-weight: 100;
	left: 0.5rem;
	right: auto;
	margin: auto;
	font-size: 2rem;
	position: absolute;
	top: calc(0.5rem + env(safe-area-inset-top));
	padding: 0;
	align-self: flex-start;
	margin: 0;
	justify-self: flex-start;
	line-height: 0;
	opacity: 1;
	background: #fff;

	@media screen and (max-width: 428px) {
	}
}
