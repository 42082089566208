.blogComments {
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: #0bc1c733;

	.addCommentForm {
		margin: 20px 0 40px;
		display: flex;
		flex-direction: column;
		align-items: end;
		gap: 10px;

		.siteBtn {
			min-width: 0;
			border-radius: 20px;
			position: absolute;
			bottom: -40px;
			left: 0;
			height: 30px;

			span {
				line-height: 1;
				display: flex;
				align-items: center;
				font-size: 15px;
				font-weight: 600;
				svg {
					transform: scale(-1);
					width: 15px;
					height: 15px;
				}
			}
		}

		.wrapTxtBtn {
			width: 100%;
			margin: 0;
			display: flex;
			align-items: center;
			padding: 10px;
			border-radius: 10px;
			background: #fff;
			position: relative;
			border: 0px solid #717171;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			gap: 12px;
			display: flex;

			.userImage img {
				object-fit: cover;
				border-radius: 50%;
				background: #ffffff;
				z-index: 1;
				border: 1px solid #fff;
				height: 50px;
				width: 50px;
			}

			.textComponent {
				.MuiInputBase-root {
					border: 0;
					padding: 0;
					.MuiInputBase-input {
						padding: 0;
					}
				}
				& > div {
					background: #fff;
				}
				width: 100%;
				background: #fff;
				border-radius: 5px;
				fieldset {
					border: 0;
					border-radius: 10px;
				}
				.Mui-focused {
					fieldset {
						border-radius: 10px;
						border: 0px #f8f8f8 solid !important;
					}
				}
			}
		}
	}

	.commentsListWrapper {
		h5 {
			margin-bottom: 30px;
		}
		.commentsList {
			display: flex;
			flex-wrap: wrap;
			gap: 40px;

			.commentBox {
				background: #fff;
				border-radius: 20px;
				padding: 40px 20px 40px 30px;
				color: #5a5a5a;
				position: relative;
				width: 100%;
				@media screen and (max-width: 780px) {
					padding: 20px;
				}

				.wrapCommentHeader {
					display: flex;
					grid-gap: 20px;
					gap: 20px;
					text-align: right;
					font-size: 16px;
					font-style: normal;
					line-height: normal;
					position: relative;
					align-items: center;
					.userImg img {
						object-fit: cover;
						border-radius: 50%;
						width: 50px;
						height: 50px;
					}
					.innerCommentHeader {
						display: flex;
						flex-direction: column;
						grid-gap: 3px;
						gap: 3px;
						font-weight: 400;

						.textDate {
							color: #2d5086;
						}
					}
					.wrapDeleteBtn {
						cursor: pointer;
					}
				}
				.wrapTxtComment {
					padding-right: 70px;
					color: #5a5a5a;
					text-align: right;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-top: 5px;
					white-space: pre-wrap;
				}
			}
		}
	}
}
