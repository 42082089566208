.registerSpeedDateWrapper {
	width: 100vw;
	height: 100vh;
	position: relative;
	.text {
		color: #fff;
		text-align: center;
		font-size: 19px;
	}
	.imgHeader {
		width: 100vw;
		position: relative;
		display: block;
		height: 50vh;
		img {
			width: 100%;
			max-height: 300px;
			object-fit: contain;
			position: fixed;
		}
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			right: 0;
			height: 15%;
			bottom: -1px;
			background: linear-gradient(
				180deg,
				rgba(45, 80, 134, 0) 10%,
				rgba(45, 80, 134, 0.8392156863) 53%,
				rgba(45, 80, 134, 0.9294117647) 59%,
				#2d5086 100%
			);
			z-index: 1;
			pointer-events: none;
		}
	}
	.speedDateContentWrapper {
		z-index: 1;
		background: #2d5086;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		min-height: 100vh;
		padding: 20px;
		padding-bottom: 70px;
		flex-direction: column;
		position: relative;

		.downArrow {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			position: fixed;
			bottom: calc(1rem + env(safe-area-inset-bottom));
			left: 1rem;
			z-index: 4;
			border-radius: 50%;
			padding: 1rem;
			background: #dff5f8;
			cursor: pointer;
			color: #2d5086;
		}
		.imgLogo {
			object-fit: contain;
			height: fit-content;
		}

		.upcomingEventBox {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.8rem;
			color: #fff;
			.formattedDate {
				display: flex;
				gap: 5px;
				justify-content: center;
				align-items: center;

				span {
					color: #fff;
					font-size: 30px;
				}
				img {
					position: relative;
					top: 3px;
				}
			}
			.eventCountDown {
				display: flex;
				justify-content: center;
				gap: 3px;
				color: #2d5086;
				min-height: 60px;
				padding: 0;
				margin: 0;

				li {
					background: #dff5f8;
					border-radius: 10px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					position: relative;
					text-decoration: none;
					min-height: 60px;
					width: 45px;

					span {
						position: absolute;
						bottom: 3px;
						font-size: 9px;
					}
					.num {
						position: relative;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
		}
	}
}
