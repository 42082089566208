.soonPage {
	@media screen and (max-width: 1680px) {
	}
	@media screen and (max-width: 780px) {
		.top-image {
			width: 100%;
			height: 250px;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1; /* Make sure the image is behind the content */
		}

		.bottom-div {
			width: 100%;
			height: calc(100vh - 100px); /* Adjust the height as needed */
			background: linear-gradient(180deg, rgba(45, 80, 134, 0) 0%, #2d5086 150px);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 2; /* Place the div above the image */
			.wrap-img {
				margin: 0;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					margin-top: 126px;
				}
			}
			.desc {
				color: #fff;
				margin-bottom: 10px;
				text-align: center;
				font-family: Rubik;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 20px;
				padding: 20px;
			}
			.timer {
				display: flex;
				padding: 20px;
				justify-content: space-between;
				.timer-box {
					width: 23%;
					height: 107px;
					color: #f65368;
					text-align: center;
					font-family: Rubik;
					font-size: 42px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					border-radius: 17px;
					background: #fff;
					.number {
						margin-top: 15px;
						margin-bottom: 3px;
					}
					.type-timer {
						font-size: 17px;
						font-weight: 400;
					}
				}
			}
		}
	}
}
