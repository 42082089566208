.phone-container {
	width: 100%;
	.phone-inner-container {
		width: 100%;
		direction: ltr;

		.PhoneInput {
			background-color: rgba(255, 255, 255, 0);
			display: flex;
			justify-content: end;
			align-items: center;
			max-height: 45px;
			width: 100%;
			height: 100%;

			.PhoneInputCountry {
				display: flex;
				flex-direction: row-reverse;
				.PhoneInputCountryIcon {
					min-width: 40px;
					width: 100%;
					display: flex;
					margin-right: 5px;
					display: none;
					img {
						align-self: center;
						height: 45px;
					}
				}
				.PhoneInputCountrySelect {
					width: 80px;
					height: 45px;
					border: 1px solid #2d5086;
					border-radius: 7px 0 0 7px;
					color: #2d5086;
					outline: 0 !important;
					font-size: 1.6rem;
					background: #fff !important;
					text-align: center;
				}
			}
			.PhoneInputInput {
				width: 70%;
				min-width: 100px;
				height: 45px;
				border: 1px solid #2d5086 !important;
				border-left: 0 !important;
				outline: none;
				color: #2d5086;
				font-size: 16px;
				width: 100%;

				&::placeholder {
					text-align: right;
					color: #2d5086 !important;
				}
			}
		}
	}
}
.homeConnect .homeContent {
	.wrapContentLP .textForDesktop .phoneLP .phone-container {
		max-width: max-content;
		.phone-inner-container {
			height: 100%;
			max-height: 45px;
		}
	}
	.phone-container .phone-inner-container .PhoneInput .PhoneInputCountry .PhoneInputCountrySelect {
		border-radius: 15px 0 0 15px;
	}
}

.loginPagesWrapper.homeConnect
	.homeContent
	.wrapContentLP
	.textForDesktop
	.phoneLP
	.phone-container
	.phone-inner-container
	.PhoneInput {
	.PhoneInputCountry {
		.PhoneInputCountrySelect {
			background: #f5f7fb !important;
		}
		.PhoneInputCountryIcon {
			display: none;
		}
	}
	.PhoneInputInput {
		&::placeholder {
			@media screen and (min-width: 1000px) {
				font-size: 2rem !important;
				position: relative;
				top: -3px;
			}
		}
	}
}
