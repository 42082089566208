$bluePageBackground: #2d5086;
@import url("https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap");

.formEventsSelect {
	width: 100%;
	.Mui-focused {
		fieldset {
			border-color: $bluePageBackground !important;
		}
	}
}

.accountVideoBox {
	background: $bluePageBackground;
	color: #fff;
	width: 100%;
	min-width: 100%;
	border-radius: 20px;
	display: grid;
	justify-content: center;
	gap: 0;
	height: 100%;
	min-height: 300px;
	overflow: auto;
	padding: 60px 12px 0;
	transition: all 0.5s;
	position: relative;

	.imgDate {
		display: flex;
		justify-content: center;
	}

	.wrapInnerVideoBox {
		color: #fff;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;
		text-align: center;
		.bold {
			font-weight: bold;
		}
		.title {
			font-size: 24px;
			font-weight: 500;
		}
		.content {
			font-size: 18px;
			min-width: 100%;
			.nearestEvent {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;

				.calligraffitti-regular {
					font-family: "Calligraffitti", cursive;
					font-weight: 500;
					width: initial;
					white-space: nowrap;
				}
				span {
					display: inline !important;
					margin: 0 !important;
				}
			}

			.wrapFeedbacksBox {
				display: flex;
				flex-direction: column;
				gap: 30px;
				padding: 20px 0;
				width: 100%;
				min-width: 100%;

				.wrapFeedbackItem {
					border: 1px solid;
					border-radius: 20px;
					padding: 40px 20px;
					min-width: 100%;
					img {
						width: 50px;
						height: 50px;
						margin: 0 10px;
						border-radius: 50%;
						object-fit: cover;
					}
					.wrapQuesAns {
						margin: 20px 0;
						display: flex;
						flex-direction: column;
						align-items: center;
						.MuiRating-root {
							margin-top: 10px;
							display: flex;
							justify-content: center;
							.MuiRating-icon {
								padding: 0px 5px;
								border-radius: 5px;
								color: #2d5086;
							}

							.MuiRating-iconFilled {
								border: #2d5086 1px solid;
							}
						}
						button {
							padding: 5px 15px;
							height: min-content;
							border-radius: 10px;
							margin-top: 10px;
							&.disabled {
								border: none !important;
							}
							&.active {
								border: 1px solid !important;
							}
						}
					}
				}
			}
		}
		.footer {
			margin-top: 20px;
			.cancelParticipant {
				border: none;
				color: #fff;
				span {
					text-decoration: underline;
				}
			}
		}

		span,
		h1,
		h2,
		h3,
		h4,
		div,
		li {
			color: #fff !important;
		}
	}
}
.addPaymentIframe {
	min-width: 100%;
	height: 90vh;
}
.drawerVideoDate {
	width: 100%;
	.explainText {
		text-align: center;
		text-decoration: underline;
		color: #2d5086;
		cursor: pointer;
	}
}
div[role="presentation"] {
	.puller {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		position: fixed;
		top: calc(1rem + env(safe-area-inset-top));
		left: 1rem;
		z-index: 2;
		border-radius: 50%;
		padding: 1rem;
		background: #dff5f8;
		cursor: pointer;
	}
}
.accountPage .detailsArea .paperDetails .wrapAllForms .accountVideoBox {
	div,
	span {
		color: #fff;
		font-size: 16px;
	}
	span {
		margin: 0;
	}
	.calligraffitti-regular {
		font-size: 18px;
	}
}
