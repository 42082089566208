.paySpeedDateWrapper {
	.speedDateContentWrapper {
		justify-content: start;
		min-height: initial;
		gap: 0px;
		padding: 0 40px 40px;
		h3 {
			margin: 40px 0;
			font-size: 24px;
		}
	}
	.speedDateFooterBtn {
		padding-bottom: 40px;
	}

	.addPaymentIframe {
		height: 80vh;
	}
	.commonTemplateSpeedDateWrapper.narrowImg .imgHeader::after {
		display: initial;
	}
}
.iframeWithoutAfterImg {
	.commonTemplateSpeedDateWrapper.narrowImg .imgHeader::after {
		display: none;
	}
}

.successfullyPaySpeedDateWrapper {
	.commonTemplateSpeedDateWrapper .imgHeader .logoBlue {
		position: fixed;
		top: calc(1rem + env(safe-area-inset-top));
		right: 1rem;
		width: auto;
	}
	.speedDateContentWrapper {
		justify-content: start;
		gap: 20px;
		padding: 0 40px 40px;
		h3 {
			font-size: 34px;
		}

		.imagesTextBox {
			padding: 20px 0;
			display: flex;
			flex-direction: column;
			gap: 30px;
			.imagesTextItem {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				img {
					margin-bottom: 20px;
				}
			}
		}
	}
}
