.youtubeUserWrapper {
	margin-top: 1.5rem;

	.wrapYouTubeVideo {
		.wrapYouTubeVideo {
			border-radius: 20px;
			overflow: hidden;
			iframe {
				border-radius: 20px;
			}
		}
	}
}
